import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Heading from 'spotii-ui/Heading';
import Button from 'spotii-ui/Button';
import Form, { InputRow, FormRow } from 'spotii-ui/Form';
import LabelValue from 'components/LabelValue';
import message from 'spotii-ui/Message';
import { refundComments } from 'api';

const RefundComments = ({ refund, show, onClose, onSuccess}) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const formik = useFormik({
    initialValues: {
      comments: refund.refundComments,
    },
    validationSchema: Yup.object().shape({
      comments: Yup.string()
        .required(t('commentsRequired'))
        .min(5)
        .max(400),
    }),
    onSubmit: async ({ comments }, actions) => {
      try {
        await refundComments(refund.transactionId, comments);
        message.success(t('refundCommentsChanged'));
        if (onSuccess) {
          onSuccess();
          actions.resetForm();
        }
      } catch (e) {
        console.warn(e);
        actions.setErrors({
          amount: (
            <span>
              {`${t('formErrorTryAgainContact')} `}
              <a href="mailto:">support@paylaterapp.com</a>
            </span>
          ),
        });
      }
    },
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
  } = formik;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    formik.resetForm();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <Heading h3>
          <p style={{ textAlign: `${currLang === 'ar' ? 'right' : 'left'}` }}>{t('changeComments')}</p>
        </Heading>
        <p
          style={{ textAlign: `${currLang === 'ar' ? 'right' : 'left'}` }}
          className="color-black40 mb-40"
        >
        </p>

        <Form onSubmit={handleSubmit}>
          <FormRow className="mb-40">
            <LabelValue label={t('orderReference')}>
              {refund.reference}
            </LabelValue>
            <LabelValue label={t('refundAmount')}>
              {refund.amount + " " +refund.currency}
            </LabelValue>
          </FormRow>
          <InputRow
            label={`${t('comments')}`}
            name="comments"
            type="text"
            value={values.comments}
            onChange={handleChange}
            onBlur={handleBlur}
            error={ errors.comments && touched.comments ? errors.comments : null}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" loading={isSubmitting} disabled={isSubmitting} onClick={submitForm}>
          {t('saveChanges')}
        </Button>
        <Button type="default" onClick={handleClose} disabled={isSubmitting}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RefundComments.propTypes = {
  refund: PropTypes.shape({
    transaction_id: PropTypes.string,
    refund_comments: PropTypes.string,
  }),
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

RefundComments.defaultProps = {
  onSuccess: null
};

export default RefundComments;
